/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from "react";
import PageHeader from "../../components/shared/elements/PageHeader";
import Alert from "../../components/shared/overlays/Alert";
import StandardsCheckCardWithChart from "../../components/shared/cards/StandardsCheckCardWithChart";
import { getStandardsCheckIndicators } from "../../../core/services/test-service";

const StandardsCheckWrapper: FC = () => {
  const [overallPassRate, setOverallPassRate] = useState<any>();
  const [examinerIntervene, setExaminerIntervene] = useState<any>();
  const [minorsFaults, setMinorsFaults] = useState<any>();
  const [seriousFaults, setSeriousFaults] = useState<any>();
  const [indicatorsCount, setIndicatorsCount] = useState<number>(0);

  useEffect(() => {
    // Function to fetch data from an API
    const fetchData = async () => {
      try {
        // Make API call using fetch or any other library like axios
        const res = await getStandardsCheckIndicators();
        if (!res.status) {
          throw new Error('Failed to fetch data');
        }
        if (res?.data) {
          setOverallPassRate(res?.data.avg_passed_tests ?? []);
          setExaminerIntervene(res?.data.avg_examiner_intervene ?? []);
          setMinorsFaults(res?.data.avg_number_of_minors_faults ?? []);
          setSeriousFaults(res?.data.avg_number_of_serious_faults ?? []);
        }
        
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    // Call the fetchData function when the component mounts
    fetchData();
  }, []);


  useEffect(() => {
    // Count the number of true boolean values
    const trueCount = [
      overallPassRate?.isHigh ?? false,
      examinerIntervene?.isHigh ?? false,
      minorsFaults?.isHigh ?? false,
      seriousFaults?.isHigh ?? false
    ].filter(value => value === true).length;
  
    // Set the number of true boolean values to indicatorsCount
    setIndicatorsCount(trueCount);
  }, [overallPassRate, examinerIntervene, minorsFaults, seriousFaults]);


  // Determine the alert message based on the number of true boolean values
  let alertMessage;
  if (indicatorsCount <= 1) {
    alertMessage = (
      <Alert
        basicAlert
        colour="darkRed"
        icon="pmf-icon-info"
        customDesc={
          <p className="text-sm">
            <span className="font-bold">Looking good!</span> We don't predict
            you'll be called for a standards check soon, however, you might
            still be asked to book a standards check during each 4-year
            registration period.
          </p>
        }
      />
    );
  } else if (indicatorsCount === 2) {
    alertMessage = (
      <Alert
        basicAlert
        colour="darkRed"
        icon="pmf-icon-info"
        customDesc={
          <p className="text-sm">
            <span className="font-bold">Heads up.</span> You may be called for
            a standards check if you trigger another indicator.
          </p>
        }
      />
    );
  } else if (indicatorsCount === 3) {
    alertMessage = (
      <Alert
        basicAlert
        colour="darkRed"
        icon="pmf-icon-info"
        customDesc={
          <p className="text-sm">
            <span className="font-bold">Warning:</span> 3 of 4 indicators have
            been triggered. You may be called for a standards check soon.
          </p>
        }
      />
    );
  } else {
    alertMessage = (
      <Alert
        basicAlert
        colour="darkRed"
        icon="pmf-icon-info"
        customDesc={
          <p className="text-sm">
            <span className="font-bold">Warning:</span> 4 of 4 indicators have
            been triggered. You may be called for a standards check soon.
          </p>
        }
      />
    );
  }



  return (
    <>
      <div className="mb-5">
        <PageHeader title="Standards Checks Indicators" backToURL="/ADI/more" />
      </div>
      <div className="mb-4">
        {indicatorsCount ? <>{alertMessage}</> 
        : <>
        <Alert loading />
        </>}
        
      </div>

      <div className="px-5">
        {overallPassRate ? (
          <>
            <StandardsCheckCardWithChart
              title="Pass rate"
              type="passed_tests"
              icon="pmf-icon-document-check"
              chartData={overallPassRate?.graph}
              chartType="line"
              indicatorNote={
                <>
                  {" "}
                  This indicator is triggered if your driving test pass rate
                  drops <span className="font-bold">below 55%</span>
                </>
              }
              avgNo={{
                val: overallPassRate?.avg,
                label: "%",
                color: (overallPassRate?.isHigh ?? false)
                ? "red"
                : (overallPassRate?.isMedium ?? false)
                ? "yellow"
                : "green",
              }}
            />
          </>
        ) : (
          <StandardsCheckCardWithChart chartData={[]} loading />
        )}

        {minorsFaults ? (
          <>
            <StandardsCheckCardWithChart
              title="Minor driving faults"
              type="number_of_minors_faults"
              icon="pmf-icon-exclamation-triangle"
              chartType="bar"
              chartData={minorsFaults?.graph}
              indicatorNote={
                <>
                  {" "}
                  This indicator is triggered if your learners commit an{" "}
                  <span className="font-bold">average of 6 or more</span> minor
                  faults per test
                </>
              }
              avgNo={{
                val: minorsFaults?.avg,
                label: "",
                color: (minorsFaults?.isHigh ?? false)
                ? "red"
                : (minorsFaults?.isMedium ?? false)
                ? "yellow"
                : "green",
              }}
            />
          </>
        ) : (
          <StandardsCheckCardWithChart chartData={[]} loading />
        )}

        {seriousFaults ? (
          <>
            <StandardsCheckCardWithChart
              title="Serious driving faults"
              type="number_of_serious_faults"
              icon="pmf-icon-exclamation-triangle"
              chartType="bar"
              chartData={seriousFaults?.graph}
              indicatorNote={
                <>
                  {" "}
                  This indicator is triggered if your learners commit an{" "}
                  <span className="font-bold">average of 0.55</span> serious
                  faults per test
                </>
              }
              avgNo={{
                val: seriousFaults?.avg,
                label: "",
                color: (seriousFaults?.isHigh ?? false)
                ? "red"
                : (seriousFaults?.isMedium ?? false)
                ? "yellow"
                : "green",
              }}
            />
          </>
        ) : (
          <StandardsCheckCardWithChart chartData={[]} loading />
        )}

        {examinerIntervene ? (
          <>
            <StandardsCheckCardWithChart
              title="Examiner Intervention"
              type="examiner_intervene"
              icon="pmf-icon-hand-raised"
              chartType="line"
              chartData={examinerIntervene?.graph}
              indicatorNote={
                <>
                  {" "}
                  This indicator is triggered if an examiner takes action in{" "}
                  <span className="font-bold">10% or more</span> of your
                  learners’ tests
                </>
              }
              avgNo={{
                val: examinerIntervene?.avg,
                label: "%",
                color: (examinerIntervene?.isHigh ?? false)
                ? "red"
                : (examinerIntervene?.isMedium ?? false)
                ? "yellow"
                : "green",
              }}
            />
          </>
        ) : (
          <StandardsCheckCardWithChart chartData={[]} loading />
        )}

        <div className="text-center px-3 mt-6 mb-10">
          <p className="text-[11px] italic">
            {" "}
            This standards check forecast is calculated from your learners’ test
            results over a rolling 12 month period
          </p>
        </div>
      </div>
    </>
  );
};

export { StandardsCheckWrapper };
