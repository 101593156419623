/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from "react";
import PageHeader from "../../components/shared/elements/PageHeader";
import { toAbsoluteUrl } from "../../helpers";
import { createClient } from "../../../../prismic";
import { PrismicRichText } from "@prismicio/react";
import { filter } from "@prismicio/client";
import Button from "../../components/shared/elements/Button";
import { PlusIcon, CheckCircleIcon } from "@heroicons/react/24/outline";
import { format } from "date-fns";
import Modal from "../../components/shared/overlays/Modal";
import TextArea from "../../components/shared/forms/TextArea";
import { addToWishList, markArticlesAsRead } from "../../../core/services/adi-service";
import toast from "react-hot-toast";
import Notification from "../../components/shared/overlays/Notification";
import { useSharedData } from "../providers/SharedDataProvider";


const UpdatesWrapper: FC = () => {
  const [updatesContent, setUpdatesContent] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [totalResults, setTotalResults] = useState<number>(0);
  const [resultsSize, setResultsSize] = useState<number>(0);
  const [pageCounter, setPageCounter] = useState(10);
  const [openAddNewFeature, setOpenAddNewFeature] = useState(false);
  const [featureText, setFeatureText] = useState('');
  const [ submitted, setSubmitted ] = useState(false)
  const { getUnread , setUnreadCountFunction } = useSharedData();
  useEffect(() => {
    const markRead = async () => {
    setUnreadCountFunction();
    await markArticlesAsRead()
    await getUnread()
   
    }
    markRead()
  },[])
  // Initial load of updates
  useEffect(() => {
    const fetchUpdates = async () => {
      setIsLoading(true);
      try {
        const response = await createClient.get({
          filters: [filter.at("document.type", "updates")],
          pageSize: pageCounter,
        });
        setUpdatesContent(response.results);
        setTotalResults(response.total_results_size);
        setResultsSize(response.results_size);
      } catch (error) {
        console.error("Error while fetching updates:", error);
      }
      setIsLoading(false);
    };

    fetchUpdates();
  }, [pageCounter, createClient]);

  const loadMoreUpdates = async () => {
    if (resultsSize < totalResults) {
      setPageCounter(pageCounter + 5);
    } else {
      setPageCounter(totalResults);
    }
  };

  const addNewFeature = async () => {
    setSubmitted(true);
    if (featureText != '') {
      await addToWishList({ "description": featureText }).catch(() => { });
      setOpenAddNewFeature(false);
      setSubmitted(false);
      setFeatureText('');
      toast.custom(
        () => (
          <Notification
            colour="green"
            title={"Saved successfully"}
            description={""}
            Icon={CheckCircleIcon}
          />
        ),
        {
          duration: 2000,
          position: "top-center"
        }
      );
    }
  };

  return (
    <>
      <div className="mb-2">
        <PageHeader title="What's New" backToURL="/ADI/more" />
      </div>

      <div className="px-5 py-6 bg-white">
        <div className="text-center ">
          <div className="flex mb-0 w-full sm:max-w-md mx-auto justify-center">
            <div className="">
              <img
                className="w-full mb-1"
                src={toAbsoluteUrl(`/assets/media/logos/updates-logo.svg`)}
                alt="ADI Network Updates Logo"
              />
            </div>
          </div>
          <p className="text-sm pt-1 pb-3">
            Please find the latest ADI Network app updates below
          </p>
        </div>
        <div className="w-full flex justify-center mx-auto">
          <img
            className="max-h-24 md:max-h-32"
            src={toAbsoluteUrl("/assets/media/svg/updates-vector.svg")}
            alt="updates vector image"
          />
        </div>
      </div>

      <div className="px-5 mt-6 flex flex-col col-span-full">
        <Button
          colour="yellow"
          type="button"
          fullWidth
          size="large"
          onClick={() => setOpenAddNewFeature(true)}
        >
          <span className="flex items-center py-1 text-sm">
            Request new feature
          </span>
        </Button>
      </div>

      {/* <!-- START:: updates list --> */}
      <div className="px-5 mt-8">
        {isLoading ? (
          <>
            Loading...
          </>
        ) : (
          <>

            <div className="grid grid-cols-1 gap-y-9 sm:gap-x-6 sm:gap-y-10 lg:gap-x-8">
              <div className="grid grid-cols-1 gap-y-7 sm:gap-y-9">
                {updatesContent?.slice(0, 10).map((item: any) => (
                  <div
                    className=" border-b-2 last:border-b-0 border-neutral-400 pb-9 last:pb-7"
                    key={item.id}
                  >
                    <h3 className="font-bold text-xl mb-5">
                      {format(new Date(item.data.update_date), 'dd/MM/yyyy')}
                    </h3>
                    <PrismicRichText
                      field={item.data.updates_content}
                      components={{
                        list: ({ children }) => (
                          <ul className="list-disc ps-5 mt-3">{children}</ul>
                        ),
                        oList: ({ children }) => (
                          <ol className="list-decimal ps-5 mt-3">{children}</ol>
                        ),
                        paragraph: ({ children }) => (
                          <p className="mt-2 text-base leading-6">{children}</p>
                        ),
                        heading3: ({ children }) => (
                          <h3 className=" mt-12 text-2xl font-titlef font-bold">
                            {children}
                          </h3>
                        ),
                        heading4: ({ children }) => (
                          <h4 className="mt-12 text-xl font-titlef font-bold">
                            {children}
                          </h4>
                        ),
                      }}
                    />
                  </div>
                ))}
              </div>
              <div className="grid grid-cols-6 gap-4 mx-auto w-full mt-6">
                {resultsSize < totalResults && (
                  //Load more button
                  <Button
                    className="col-span-4 col-start-2"
                    colour="outline"
                    onClick={loadMoreUpdates}
                    size="large"
                  >
                    <div className="flex items-center text-base font-semibold">
                      LOAD MORE <PlusIcon className="w-5 h-5 ms-2" />
                    </div>
                  </Button>
                )}
                <p className="col-span-6 text-[#616161] text-sm text-center mb-7">
                  Showing {resultsSize} of {totalResults}
                </p>
              </div>
            </div>
          </>
        )}
      </div>
      <Modal
        open={openAddNewFeature}
        onClose={() => { setOpenAddNewFeature(false); setFeatureText('');setSubmitted(false) }}
        onSubmit={() => { addNewFeature() }}
        submitText='Submit'
        closeText='Cancel'
        title=''
        description="We'd love to know what you'd like to see next on the ADI Network app, please enter your ideas and feature requests below. A member of the ADI Network team may reach out to you to discuss your request in more detail"
      >
        <div className="my-6">
          <TextArea
            name="features"
            label="Enter your requests"
            className="form-control"
            onChange={(e) => setFeatureText(e.target.value)}
            value={featureText}
            required={true}
            error={submitted && featureText === ''}
          />
        </div>
      </Modal>

      {/* <!-- END:: updates list --> */}
    </>
  );
};

export default UpdatesWrapper;
