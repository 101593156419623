import { NavLink, useLocation } from "react-router-dom";
import { checkIsActive } from "../helpers";
import { PersonalDataModel } from "../../core/models/personal-data-model";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "../../../setup";
import { useEffect, useState } from "react";
import Badge from "../components/shared/elements/Badge";
import { getArticlesUnReadCount } from "../../core/services/adi-service";
import { useSharedData } from "../pages/providers/SharedDataProvider";

const navigation = [
  {
    name: "Home",
    href: "/ADI/dashboard",
    icon: "pmf-icon-home",
  },
  {
    name: "Calendar",
    href: "/ADI/lessons",
    icon: "pmf-icon-calendar",
  },
  {
    name: "Jobs",
    href: "/ADI/new-jobs",
    icon: "pmf-icon-wheel",
    storageKey: 'new_jobs_query',

  },
  {
    name: "Learners",
    href: "/ADI/students",
    icon: "pmf-icon-user",
  },
  {
    name: "Finance",
    href: "/ADI/payments",
    icon: "pmf-icon-currency-pound",
  },
  {
    name: "More",
    href: "/ADI/more",
    icon: "pmf-icon-more-outline",
  },
];

const MenuLinks = () => {
  const { pathname } = useLocation()
  const [navigationLinks, setNavigationLink] = useState(navigation)
  const { unReadCount , getUnread } = useSharedData();
  const [unReadArticleCount, setunReadArticleCount] = useState<any>(0);
  const personalData: PersonalDataModel = useSelector<RootState>(({ auth }) => auth.personalData, shallowEqual) as PersonalDataModel

  useEffect(()=>{
    getUnread()
    setunReadArticleCount(unReadCount)
  },[unReadCount])
  useEffect(() => {
    if (personalData) {
      if (((personalData?.is_impersonate == 1 && personalData?.hide_new_jobs_tab_for_impersonate == 1)
        || (personalData?.is_impersonate == 0 && personalData?.hide_new_jobs_tab == 1))) {
        setNavigationLink(navigation.filter((c) => c.name !== "Jobs"));
      } else if (((personalData?.is_impersonate == 1 && personalData?.hide_new_jobs_tab_for_impersonate == 0)
        || (personalData?.is_impersonate == 0 && personalData?.hide_new_jobs_tab == 0))) {
        setNavigationLink(navigation);
      }
    }

  }, [personalData])


  return (
    <>
      <div className='flex h-full max-w-lg lg:flex-col flex-row lg:items-start items-center lg:justify-start justify-around lg:mx-0 lg:gap-y-4 mx-auto'>
        {navigationLinks.map((item, i) => (
          <NavLink
            key={i}
            className={`${checkIsActive(pathname, item.href) ? "text-white" : "text-indigo-200 hover:text-white "}
              group flex gap-x-3 rounded-md sm:py-2 py-6 text-sm leading-6 font-semibold lg:flex-row flex-col items-center justify-center`
            }
            // to={item.href}
            to={item.storageKey && sessionStorage.getItem(item.storageKey) != null ? item.href + sessionStorage.getItem(item.storageKey) : item.href}
          >
            <div className="relative">
              <i className={`${item.icon} text-[24px] lg:mb-0 mb-2`}></i>
              {(unReadArticleCount && i ==navigationLinks.length -1  )? 
               <div className="absolute -top-2.5 -right-2">
                <Badge notificationBadge={true} title={unReadCount} size="mdBadge" />
              </div>
             : null }
             
            </div>
            
            <span className="lg:text-sm text-xs">{item.name}</span>
            
            
            
                          

          </NavLink>
        ))}
      </div>
      <div className="hidden text-indigo-200 order-2 text-sm text-center text-base lg:block">
        <span className="font-semibold">
          &copy; {new Date().getFullYear()} owned by Star Genie Limited
        </span>
      </div>
    </>
  );
};

export default MenuLinks;
