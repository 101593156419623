/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from "react";
import DisclosureItem from "../../components/shared/elements/DisclosureItem";
import ListGroup from "../../components/shared/data/ListGroup";
import { ProfessionalDevelopment } from "../../components/account-settings/ProfessionalDevelopment";
import PageHeader from "../../components/shared/elements/PageHeader";
import { checkInNewYear } from "../../../core/services/adi-service";
import { useSharedData } from "../providers/SharedDataProvider";
import { AdiNetworkPagesEnum } from "../../../core/models/enums/adi-network-pages-enum";
import { saveAdiClickedPage } from "../../../core/services/adi-service";

const MoreWrapper: FC = () => {
  const adiClickedPage = async (e: any) => {
    await saveAdiClickedPage(e).catch(() => {});
  };

  const [networkPerksMenu, setNetworkPerksMenu] = useState([
    {
      name: "Earn commission with GoShorty",
      linkURL: "/ADI/pass-perks/goshorty",
    },
    {
      name: "Referral Scheme",
      linkURL:
        process.env.REACT_APP_ADINETWORK_URL + "/network-perks/referral-reward",
      isRedirectLink: true,
      onClick: () => {
        adiClickedPage({
          page_url:
            process.env.REACT_APP_ADINETWORK_URL +
            "/network-perks/referral-reward",
          page_name: AdiNetworkPagesEnum.ReferralScheme,
        });
      },
    },
  ]);
  const { unReadCount, getUnread } = useSharedData();
  const [unReadArticleCount, setunReadArticleCount] = useState<any>(0);
  useEffect(() => {
    getUnread();
    setunReadArticleCount(unReadCount);
  }, [unReadCount]);
  useEffect(() => {
    (async () => {
      const res = await checkInNewYear();

      if ((res?.results ?? true) === false) {
        setNetworkPerksMenu([
          ...networkPerksMenu,
          {
            name: "Discount & Rewards",
            linkURL: "/ADI/pass-perks/discounts-and-rewards",
          },
        ]);
      }
    })();
  }, []);

  return (
    <>
      <div className="px-5">
        <div className="mb-5">
          <PageHeader title="More"></PageHeader>
        </div>

        <ProfessionalDevelopment />
        <DisclosureItem
          disclosureCard
          title="Network Perks"
          subTitle="Lorem ipsum dolor sit amet"
          disclosureIcon="pmf-icon-gift"
        >
          <div className=" px-4">
            <ListGroup data={networkPerksMenu} />
          </div>
        </DisclosureItem>

        <DisclosureItem
          disclosureCard
          title="Help & Feedback"
          subTitle="Lorem ipsum dolor sit amet"
          disclosureIcon="pmf-icon-help"
          expanded={unReadArticleCount > 0}
        >
          <div className=" px-4">
            <ListGroup
              data={[
                {
                  name: "Customer Service",
                  linkURL: "/ADI/help",
                },
                {
                  name: "Terms and Conditions",
                  linkURL: process.env.REACT_APP_ADINETWORK_URL + "/terms",
                  isRedirectLink: true,
                  onClick: () => {
                    adiClickedPage({
                      page_url: process.env.REACT_APP_ADINETWORK_URL + "/terms",
                      page_name: AdiNetworkPagesEnum.TermsAndConditions,
                    });
                  },
                },
                {
                  name: "What's New",
                  linkURL: "/ADI/updates",
                  notificationCount: unReadArticleCount,
                },
                {
                  name: "FAQs",
                  linkURL: process.env.REACT_APP_ADINETWORK_URL + "/faq",
                  isRedirectLink: true,
                  onClick: () => {
                    adiClickedPage({
                      page_url: process.env.REACT_APP_ADINETWORK_URL + "/faq",
                      page_name: AdiNetworkPagesEnum.FAQs,
                    });
                  },
                },
              ]}
            />
          </div>
        </DisclosureItem>
      </div>
    </>
  );
};

export { MoreWrapper };
