export class DrivingTuitionModel {
    public start_teach_year: number|undefined|string='';
    public adi_number: number|undefined|string = '';
    public adi_grade: string|undefined='';
    public franchise_name: string|undefined='';
    public is_adi_trainer: number|undefined=0;
    public is_with_franchise: number|undefined=0;
    public reserved_hours_count: number|undefined|string = '';
    public part_three_exam_date:any='';
    public has_required_badge_documents: number|undefined=0;


    
}
  