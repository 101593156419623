import { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  acceptJobUpgrade,
  newJobsDashboard,
  upgradedJobsDashboard,
} from "../../../../core/services/job-service";
import DescriptionList from "../../shared/data/DescriptionList";
import DisclosureItem from "../../shared/elements/DisclosureItem";
import LinkItem from "../../shared/elements/LinkItem";
import Slider from "react-slick";
import Modal from "../../shared/overlays/Modal";
import toast from "react-hot-toast";
import Notification from "../../shared/overlays/Notification";
import {
  ExclamationCircleIcon,
  CheckCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import { PersonalDataModel } from "../../../../core/models/personal-data-model";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "../../../../../setup";

const NewJobsSummary: FC<any> = ({ setNewJobsCount, setIsDocumentMissing }) => {
  const history = useHistory();
  const [newJobs, setNewJobs] = useState<Array<any>>([]);
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [isLoading, setIsloading] = useState<boolean>(true);
  const [count, setCount] = useState<number>(0);
  const [newCount, setNewCount] = useState<number>(0);
  const [upgradedCount, setUpgradedCount] = useState<number>(0);
  const [allJobs, setAllJobs] = useState<Array<any>>([]);
  const [submiting, setSubmiting] = useState<boolean>(false);
  const [showNewJobs, setShowNewJobs] = useState<boolean>(false);
  const [upgradedJobs, setUpgradedJobs] = useState<Array<any>>([]);
  const [showMaxWarning, setShowMaxWarning] = useState(false);
  const personalData: PersonalDataModel = useSelector<RootState>(
    ({ auth }) => auth.personalData,
    shallowEqual
  ) as PersonalDataModel;

  var settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1048,
        settings: {
          slidesToShow: 1.75,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1.5,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1.25,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 350,
        settings: {
          slidesToShow: 0.9,
        },
      },
    ],
  };
  function viewDetails() {
    setShowDetails(true);
  }
  var jobsRows = allJobs?.map((job: any, index: number) => {
    let pills: Array<{
      title: string;
      colour?:
        | "blue"
        | "green"
        | "darkGreen"
        | "yellow"
        | "red"
        | "outlineRed"
        | "gray"
        | "darkRed";
    }> = [
      {
        colour: "green",
        title: job?.transmission_type_name,
      },
    ];

    if (job?.is_updated_job == 1) {
      pills.push({
        colour: "red",
        title: "Updated",
      });
    }
    if (
      job?.is_parallel_tbc == 1 ||
      job?.is_test_date_confirmed == 0 ||
      (job?.is_private_student == 1 &&
        job?.test_date == null &&
        job?.test_center_name == null)
    ) {
      pills.push({
        colour: "blue",
        title: `TBC`,
      });
    }

    /**
     * Pill for Lesson only
     */
    if (
      (!job?.test_center_name || !job?.test_date) &&
      job?.pt_required_at == null
    ) {
      pills.push({
        colour: "yellow",
        title: `Lessons only`,
      });
    }

    let card: Array<{
      icon?:
        | "pmf-icon-map-pin"
        | "pmf-icon-currency-pound"
        | "pmf-icon-calendar"
        | "pmf-icon-intensity";
      img?: string;
      value: string;
      type: string;
      info?: string;
      isIntensity?: boolean;
      isAvailability?: boolean;
    }> = [
      {
        icon: "pmf-icon-map-pin",
        type: "Postcode",
        value: job?.student_post_code,
        info: job?.distance ? job?.distance?.toFixed(1) + "mi away" : undefined,
      },
      {
        icon: "pmf-icon-currency-pound",
        type: "Hourly rate",
        value: Number.isInteger(job?.rate_per_hour)
          ? "£" + job?.rate_per_hour?.toLocaleString()
          : Number(job?.rate_per_hour)
          ? "£" + job?.rate_per_hour?.toFixed(2)
          : job?.rate_per_hour,
        info: Number.isInteger(job?.total_rate)
          ? "£" + job?.total_rate?.toLocaleString() + " total"
          : job?.total_rate != null
          ? "£" + job?.total_rate?.toFixed(2) + " total"
          : "",
      },
    ];

    if (job?.availability_match && job?.availability_match >= 20) {
      let match = "Great";
      if (job?.availability_match >= 20 && job?.availability_match <= 34) {
        match = "Fair";
      }
      if (job?.availability_match >= 35 && job?.availability_match < 50) {
        match = "Good";
      }
      card.push({
        icon: "pmf-icon-calendar",
        type: "Availability match",
        img: "availability/availability-" + match + ".svg",
        value: match,
        isAvailability: true,
      });
    } else {
      if (job?.course_intensity != null) {
        card.push({
          icon: "pmf-icon-intensity",
          type: "Intensity",
          value: job?.course_intensity,
          isIntensity: true,
        });
      }
    }

    function acceptJobUpgradeFn(jobId: any) {
      let tempUpgraded: Array<any> = [...upgradedJobs];
      let index = tempUpgraded.findIndex((a) => a.id == jobId);
      if (index > -1) {
        tempUpgraded[index].loading = true;
      }
      setUpgradedJobs(tempUpgraded);
      let loadingId = toast.custom(
        () => (
          <Notification
            colour="gray"
            title={"Accepting job upgrade..."}
            description={""}
            Icon={ExclamationCircleIcon}
          />
        ),
        {
          duration: 5000,
          position: "top-center",
        }
      );

      acceptJobUpgrade(jobId)
        .then((data) => {
          toast.custom(
            () => (
              <Notification
                colour="green"
                title={"Saved successfully"}
                description={""}
                Icon={CheckCircleIcon}
              />
            ),
            {
              duration: 5000,
              position: "top-center",
              id: loadingId,
            }
          );
          getUpgradedJobs();
        })
        .catch((error) => {
          setSubmiting(false);
          if (error && error.errors && error.errors.length > 0) {
            if (index > -1) {
              tempUpgraded[index].loading = false;
            }
            setUpgradedJobs(tempUpgraded);
          }
          toast.custom(
            () => (
              <Notification
                colour="red"
                title={"Failed to accept job upgrade!"}
                description={""}
                Icon={ExclamationTriangleIcon}
              />
            ),
            {
              duration: 5000,
              position: "top-center",
              id: loadingId,
            }
          );
        });
    }
    const practicalTestData = () => {
      let testData: {
        icon: "pmf-icon-clock" | "pmf-icon-test" | undefined;
        value: string | undefined;
      }[] = [];

      if (
        (!job?.test_center_name || !job?.test_date) &&
        job?.pt_required_at == null
      ) {
        /**
         * Lessons Only. Mark location and time as N/A
         */
        testData.push({
          icon: "pmf-icon-test",
          value: "N/A",
        });
        testData.push({
          icon: "pmf-icon-clock",
          value: "N/A",
        });
      } else {
        if (job?.test_center_name || job?.test_preferred_test_centers) {
          testData.push({
            icon: "pmf-icon-test",
            value: job?.test_center_name ?? job?.test_preferred_test_centers,
          });
        }

        if (job?.test_date_formatted || job?.test_preferred_dates_str) {
          testData.push({
            icon: "pmf-icon-clock",
            value: job?.test_date_formatted ?? job?.test_preferred_dates_str,
          });
        }
      }

      return testData;
    };
    return (
      <div className="pr-3" key={index}>
        <DescriptionList
          loading={isLoading}
          old_hours={job?.old_hours}
          title={job?.hours + " Hours"}
          pmfIcon={true}
          pills={pills}
          data={practicalTestData()}
          cardData={card}
          buttons={[
            {
              loading: job?.loading,
              text: (
                <span>
                  {job?.old_hours ? "Accept Upgrade" : "View Details"}
                </span>
              ),
              size: "large",
              onClick: () => {
                if (job?.old_hours) {
                  acceptJobUpgradeFn(job?.id);
                } else {
                  if (job?.has_exceeded_max_jobs == 1) {
                    setShowMaxWarning(true);
                  } else {
                    history.push("/ADI/new-jobs/details/" + job?.id);
                  }
                }
              },
            },
          ]}
        />
      </div>
    );
  });
  function getUpgradedJobs() {
    upgradedJobsDashboard()
      .then((res) => {
        setUpgradedJobs(res.data.results.Results);
        setUpgradedCount(res.data.results.TotalRecords);
        if (res.data.results.TotalRecords > 0) {
          setShowNewJobs(true);
        }
        setIsloading(false);
      })
      .catch((error) => {
        setIsloading(false);
        setUpgradedCount(0);
      });
  }
  function getNewJobs() {
    setShowNewJobs(true);
    newJobsDashboard()
      .then((res) => {
        setNewJobs(
          res.data.results.Results.length > 0 ? res.data.results.Results : []
        );

        setNewCount(res.data.results.TotalRecords);
        setIsloading(false);
      })
      .catch((response) => {
        setIsloading(false);
        setNewCount(0);
      });
  }
  useEffect(() => {
    setIsloading(true);
    if (personalData) {
      if (
        (personalData?.is_impersonate == 1 &&
          personalData?.hide_new_jobs_tab_for_impersonate == 0) ||
        (personalData?.is_impersonate == 0 &&
          personalData?.hide_new_jobs_tab == 0)
      ) {
        getNewJobs();
      }
      getUpgradedJobs();
    }
  }, [personalData]);

  useEffect(() => {
    setCount(newCount + upgradedCount);
    setNewJobsCount(newCount + upgradedCount);
  }, [newCount, upgradedCount]);

  useEffect(() => {
    setAllJobs([...upgradedJobs, ...newJobs]);
  }, [newJobs, upgradedJobs]);

  return (
    <>
      <Modal
        open={showMaxWarning}
        hideSubmit={true}
        title="Job limit"
        description={
          "We are unable to allocate you this as you have the maximum number of active jobs"
        }
        closeText={"Close"}
        onClose={() => {
          setShowMaxWarning(false);
        }}
      />
      {showNewJobs && (
        <DisclosureItem
          title="New Jobs"
          count={count}
          loading={isLoading}
          linkName="jobs"
          linkURL="/ADI/new-jobs"
          storageKey="new_jobs_query"
        >
          <Slider {...settings}>{jobsRows}</Slider>

          <div className="flex justify-end w-full mt-[24px]">
            <LinkItem
              seeAllIcon={true}
              url={
                sessionStorage.getItem("new_jobs_query") != null
                  ? "/ADI/new-jobs" + sessionStorage.getItem("new_jobs_query")
                  : "/ADI/new-jobs"
              }
            >
              <span className="text-sm">See all jobs</span>
            </LinkItem>
          </div>
        </DisclosureItem>
      )}
    </>
  );
};

export { NewJobsSummary };
