import { FC } from 'react'
import PageHeader from '../../components/shared/elements/PageHeader'
import { DigitalPaymentsComponent } from '../../components/account-settings/digital-payments/DigitalPaymentsComponent'

export const DigitalPaymentsWrapper: FC<any> = () => {
    return (
        <div>
            <div className='mb-5'>
                <PageHeader title='Digital Payment Account' backToURL='/ADI/settings/overview' />
            </div>
            <DigitalPaymentsComponent />
        </div>
    )
}