import { FC, useEffect, useState } from "react";
import ListGroup from "../shared/data/ListGroup";
import DisclosureItem from "../shared/elements/DisclosureItem";
import { createClient } from "../../../../prismic";
import { filter } from "@prismicio/client";

const ProfessionalDevelopment: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [pages, setPages] = useState<any>();

  // Initial load of articles
  useEffect(() => {
    //console.log("*** Initial load of articles ***");
    const fetchPages = async () => {
      setIsLoading(true);
      try {
        const response = await createClient.get({
          filters: [filter.at("document.type", "page")],
        });
        //console.log(response); // Log the response object
        const mappedArticles = response?.results?.map((result) => ({
          name: result.data.title,
          linkURL: "/ADI/professional-development/" + result.uid,
        }));
        //console.log(mappedArticles); // Log the response object
        setPages(mappedArticles);
        //console.log(pages); // Log the response object
      } catch (error) {
        console.error("Error while fetching articles:", error);
      }
      setIsLoading(false);
    };

    fetchPages();
  }, [createClient]);

  return (
    <>
      {/* {isLoading ? (
        <>
          Loading...
        </>
      ) : ( */}
      <>
        <DisclosureItem
          disclosureCard
          loading={isLoading}
          title="Professional Development"
          subTitle="Lorem ipsum dolor sit amet"
          disclosureImg="/assets/media/svg/help.svg"
        >
          <div className=" px-4">
            <ListGroup
              className="border-b border-[#E6E6E6]"
              data={[
                {
                  name: "All Videos and Articles",
                  linkURL: "/ADI/cpd",
                },
              ]}
            />

            <ListGroup data={pages} />

            <ListGroup
              className="border-t border-[#E6E6E6]"
              data={[
                {
                  name: "Standards Check Indicators",
                  linkURL: "/ADI/standards-check-indicators",
                },
              ]}
            />
          </div>
          {/* <ArticlesVideosWrapper /> */}
        </DisclosureItem>
      </>
      {/* )} */}
    </>
  );
};

export { ProfessionalDevelopment };
