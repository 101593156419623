/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { LessonsList } from '../../components/lessons/lessonsList'
import PageHeader from '../../components/shared/elements/PageHeader'
const LessonsWrapper: FC = () => {
  return (
    <>
      <div className='px-5 mb-5'>
        <PageHeader title='Calendar' />
      </div>
      <LessonsList />
    </>
  )
}

export { LessonsWrapper }
