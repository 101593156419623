/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import CompleteADIQuestionnaire from "../../components/questionnaire/CompleteADIQuestionnaire";

const CompleteADIQuestionnaireWrapper: FC = () => {
  return (
    <>
      <div className='mb-20'>
        <CompleteADIQuestionnaire />
      </div>
    </>
  );
};

export { CompleteADIQuestionnaireWrapper };
