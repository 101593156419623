/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import PageHeader from '../../components/shared/elements/PageHeader'
import { DirectPaymentDetails } from '../../components/account-settings/digital-payments/DirectPaymentDetails'

const CheckoutsWrapper: FC = () => {

    return (
        <>
            <PageHeader title='Checkout details' backToURL='/ADI/digital-payments'/>
            <DirectPaymentDetails />
        </>
    )
}

export default CheckoutsWrapper
