import React from "react";
import Badge from "../elements/Badge";
import Button from "../elements/Button";
import DescriptionListHeader from "./DescriptionListHeader";
import InfoList from "./InfoList";
import { StudentCard } from "../cards/StudentCard";
import Skeleton from "@mui/material/Skeleton";
import { GrayCard } from "../cards/GrayCard";
import { toAbsoluteUrl } from "../../../helpers";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { LearnerJobStatusEnum } from "../../../../core/models/enums/learner-job-status-enum";
import { TrashIcon } from "@heroicons/react/20/solid";

export type DescriptionListProps = {
  title?: string;
  pmfIcon?: boolean;
  jobRef?: string | null;
  isPrivate?: React.ReactNode;
  rounded?: boolean;
  bidStatus?: string;
  bidStatusObject?: any;
  hideButton?: {
    disabled?: boolean;
    text: React.ReactNode;
    onClick: () => void;
  };
  editButtons?: {
    title: string;
    Icon: any;
    show: boolean;
    onClick: () => void;
  }[];
  loading?: boolean;
  old_hours?: number | null | undefined;
  data?: {
    icon:
    | "pmf-icon-map-pin"
    | "pmf-icon-suitcase"
    | "pmf-icon-user"
    | "pmf-icon-clock"
    | "pmf-icon-test"
    | "pmf-icon-info"
    | undefined;
    value: string | undefined;
    badge?: boolean;
    linkURL?: string;
  }[];
  cardData?: {
    icon?:
    | "pmf-icon-map-pin"
    | "pmf-icon-currency-pound"
    | "pmf-icon-calendar"
    | "pmf-icon-intensity";
    value: string;
    type: string;
    info?: string;
    img?: string;
    isIntensity?: boolean;
    isAvailability?: boolean;
  }[];
  buttons?: {
    text: React.ReactNode;
    disabled?: boolean;
    halfWidth?: boolean;
    loading?: boolean | undefined;
    size?: string;
    colour?: "yellow" | "darkBlue" | "outline" | "gray";
    onClick: () => void;
  }[];
  pills?: {
    title: string;
    colour?:
    | "blue"
    | "green"
    | "darkGreen"
    | "yellow"
    | "red"
    | "outlineRed"
    | "gray"
    | "darkRed";
  }[];
  children?: React.ReactNode;
  stdData?: {
    title: string | undefined;
    location: number | string | undefined;
    description?: string;
    phone?: string;
    email?: string;
    is_completed_job?: null;
    learner_job_status_id?: number;
    medical_conditions?: Array<any> | null | undefined;
    completeReason?: string;
    onViewMedical?: () => void;
  };
  isRelevantJob?: boolean;
  isSmartSorted?: boolean;
};

export default function DescriptionList({
  title,
  pmfIcon = false,
  bidStatus,
  bidStatusObject,
  hideButton,
  old_hours,
  jobRef,
  rounded = true,
  loading = false,
  isPrivate = 0,
  data,
  pills,
  buttons,
  children,
  cardData,
  stdData,
  editButtons,
  isRelevantJob = false,
  isSmartSorted = false,
}: DescriptionListProps) {
  const getStudentStatus = (status: number) => {
    if (status === LearnerJobStatusEnum.Complete) {
      return {
        title: "Complete",
        color: "darkGreen",
      };
    } else if (status === LearnerJobStatusEnum.OnHold) {
      return {
        title: "On Hold",
        color: "darkBlue",
      };
    } else if (status === LearnerJobStatusEnum.WaitList) {
      return {
        title: "Waitlist",
        color: "darkGray",
      };
    } else {
      return {
        title: "Active",
        color: "yellow",
      };
    }
  };

  return (
    //   <div className="bg-white p-4 rounded-md min-w-[340px] text-center justify-center flex items-center flex-col space-y-4 py-10">
    //     <p className="text-slate-700">Loading</p>
    //     {/* <Spinner className="w-8 h-8 text-slate-600" /> */}
    //   </div>
    // ) : (

    <div
      className={`${rounded ? "rounded-md" : ""
        } bg-white p-4 text-darkBlue h-full flex flex-col justify-between`}
    >
      <div>
        <DescriptionListHeader
          title={title}
          pmfIcon={pmfIcon}
          hideButton={hideButton}
          jobRef={jobRef}
          subTitle={old_hours}
          editButtons={editButtons}
          loading={loading}
          isRelevantJob={isRelevantJob}
          isSmartSorted={isSmartSorted}
        />

        <div
          className={`${bidStatus ? "-mb-3" : ""
            } flex items-start justify-between`}
        >
          <div className="flex">
            {loading ? (
              <Skeleton width={30} className="me-2" />
            ) : (
              pills?.map((pill, i) => (
                <div className="flex items-center mr-2" key={i}>
                  <Badge colour={pill.colour} title={pill.title} />
                </div>
              ))
            )}
          </div>

          {loading ? (
            <Skeleton width={30} className="me-2" />
          ) : (
            bidStatus && (
              <div className="flex flex-col text-end justify-end items-end">
                <div className="flex justify-center justify-items-center">
                  {(bidStatusObject?.canEdit == 1 || bidStatusObject?.canDelete == 1) && (
                    <>
                      {bidStatusObject?.canDelete == 1 && <TrashIcon
                        className="w-3"
                        onClick={() => {
                          bidStatusObject?.onClick(
                            bidStatusObject?.jobRef,
                            false
                          );
                        }}
                      />}
                      {bidStatusObject?.canEdit == 1 && <img
                        src="/assets/media/svg/bids/edit.svg"
                        className=" w-3"
                        onClick={() => {
                          bidStatusObject?.onClick(
                            bidStatusObject?.jobRef,
                            true
                          );
                        }}
                      />}
                    </>
                  )}
                  <p className="px-2 text-[12px] font-bold text-darkBlue">
                    Bid status:
                  </p>
                </div>

                <div className="flex justify-center justify-items-center">
                  {bidStatusObject.img ? (
                    <img
                      src={bidStatusObject.img}
                      className="px-2 w-auto h-auto"
                    />
                  ) : (
                    <></>
                  )}

                  <p
                    className={`text-[11px] text-${bidStatusObject?.colour ?? ""
                      }`}
                  >
                    {bidStatusObject?.status}
                  </p>
                </div>
              </div>
            )
          )}
        </div>

        <div className="mb-3">
          {data && <InfoList loading={loading} data={data} />}
        </div>

        {cardData && (
          <div
            className={`${cardData.length > 3 ? "grid-cols-2" : "grid-cols-3"
              } grid gap-[8px] pt-1`}
          >
            {cardData?.map((item, i) => (
              <div key={i}>
                <GrayCard
                  title={item.type}
                  topIcon={item.icon}
                  value={item.value}
                  smallCard={true}
                  info={item.info}
                  img={item.img}
                  loading={loading}
                  valueNoWrap={item?.isIntensity}
                  addSpace={item?.isAvailability}
                />
              </div>
            ))}
          </div>
        )}

        {stdData && (
          <div className="mt-4">
            <StudentCard
              loading={loading}
              title={stdData?.title}
              description={stdData?.description}
              location={stdData?.location}
              phone={stdData?.phone}
              email={stdData?.email}
              completeReason={stdData?.completeReason}
              medicalConditions={
                stdData?.medical_conditions &&
                  stdData?.medical_conditions?.length > 0 ? (
                  <>
                    <div
                      className={`flex items-center justify-between w-full mt-1 cursor-pointer h-3`}
                      onClick={stdData?.onViewMedical}
                    >
                      <span
                        className={`flex flex-grow-1 items-center leading-1 text-xs font-semibold`}
                      >
                        <img
                          src={toAbsoluteUrl(
                            "/assets/media/svg/medical-icon.svg"
                          )}
                          className="text-base text-darkBlue me-1 leading-none -ms-1 -mt-[1px] w-4"
                        />{" "}
                        View Medical Condition
                      </span>
                      <div className=" flex-shrink-0">
                        {" "}
                        <ChevronRightIcon className=" w-4 h-4" />
                      </div>
                    </div>
                  </>
                ) : null
              }
              status={
                stdData?.learner_job_status_id != null
                  ? getStudentStatus(stdData?.learner_job_status_id)
                  : null
              }
            />
          </div>
        )}
      </div>
      {loading ? (
        <Skeleton
          className="w-full mt-4 !rounded-full"
          height={35}
          animation="wave"
          variant="rounded"
        ></Skeleton>
      ) : (
        buttons && (
          <div className="mt-4 mb-1 flex space-x-2 justify-between">
            {buttons.map((button, i) => (
              <Button
                onClick={() => button.onClick()}
                key={i}
                disabled={button?.disabled}
                colour={button?.colour}
                size={button?.size}
                halfWidth={button?.halfWidth}
              >
                {button.text}
              </Button>
            ))}
          </div>
        )
      )}
    </div>
  );
}
