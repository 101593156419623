/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
const CurrentJobsWrapper: FC = () => {
  return (
    <>
      <div className='d-flex flex-wrap px-5 py-6 p-lg-0'>
      CurrentJobsWrapper works
      </div>
    </>
  )
}

export { CurrentJobsWrapper }
