
// import { useNavigate } from "react-router-dom";

const Page404 = () => {
  // const navigate = useNavigate();

  // const handleBackToHome = () => navigate("/");

  return (
   <></>
  );
};

export default Page404;
