
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import PageHeader from '../../components/shared/elements/PageHeader';
import ContactInfo from './ContactInfo';

const HelpWrapper: FC = () => {

  return (
    <>
      <PageHeader title='Help & Feedback' backToURL='/ADI/settings/overview'></PageHeader>

      <ContactInfo isNotAllowed={false}></ContactInfo>
    </>
  )
}

export default HelpWrapper