/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import PageHeader from '../../components/shared/elements/PageHeader'
import { JobDetailsComponent } from '../../components/jobs/job-details/JobDetailsComponent'

const JobDetailsWrapper: FC = () => {
    return (
        <>
            <div className='mb-5'>
                <PageHeader title='Job details' backToURL='/ADI/students' />
            </div>
            <JobDetailsComponent />
        </>

    )
}

export { JobDetailsWrapper }
